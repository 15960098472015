import React from 'react';
import { loadModules } from 'esri-loader';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    loadModules(['esri/Map', 'esri/views/MapView', 'esri/layers/MapImageLayer'], { css: true })
      .then(([ArcGISMap, MapView, MapImageLayer]) => {
        const layer = new MapImageLayer({
          url: window.environment.webmap
        });
  
        const map = new ArcGISMap({
          basemap: 'topo-vector',
          layers: [layer]
        });
  
        new MapView({
          container: this.mapRef.current,
          map: map,
          zoom: 4,
          center: [15, 65] // Longitude, latitude
        });
      });
  }

  render() {
    return (
      <div>
        <h1>Hello World!</h1>
         <p>{window.environment.hello_text}</p> 
        <div ref={this.mapRef} style={{ height: 500, width: 500 }}></div>
      </div>
    );
  }
}

export default App;

